import { Children, forwardRef } from "react";
import { tv } from "tailwind-variants";

import { cn } from "../../utils/cn";
import { createContext } from "../../utils/createContext";
import { Divider } from "../divider/Divider";

import type { ComponentPropsWithoutRef, ReactNode } from "react";
import type { VariantProps } from "tailwind-variants";
import type { ForwardRefComponentWithSubcomponents, SlotsToClasses } from "../../utils/types";

const calloutVariants = tv({
  slots: {
    base: [
      "flex",
      "flex-col",
      "gap-1",
      "rounded",
      "border",
      "border-solid",
      "border-gray-100",
      "bg-gray-20",
      "px-4",
      "py-3",
      "dark:border-gray-700",
      "dark:bg-gray-800",
    ],
    content: "grid grid-cols-[auto,1fr] grid-rows-[auto,auto] items-center gap-x-2 gap-y-1",
    icon: "text-base text-gray-850 dark:text-white",
    line: "justify-self-center bg-gray-100 dark:bg-gray-700",
    title: "text-sm font-semibold text-gray-850 dark:text-white",
    description: "text-sm text-gray-850 dark:text-white",
  },
  variants: {
    showLines: {
      true: {},
      false: {
        line: "invisible",
      },
    },
  },
  defaultVariants: {
    showLines: true,
  },
});

type CalloutSlots = ReturnType<typeof calloutVariants>;
type CalloutClassNames = SlotsToClasses<keyof CalloutSlots>;

interface CalloutContext {
  slots: CalloutSlots;
  classNames?: CalloutClassNames;
}

const [CalloutProvider, useCalloutContext] = createContext<CalloutContext>({
  name: "CalloutContext",
});

export interface CalloutProps
  extends ComponentPropsWithoutRef<"div">,
    VariantProps<typeof calloutVariants> {
  /** Classes to apply to the slots */
  classNames?: SlotsToClasses<keyof ReturnType<typeof calloutVariants>>;
}

export const Callout = forwardRef<HTMLDivElement, CalloutProps>(
  ({ className, classNames, children, showLines: showLinesProp, ...props }, ref) => {
    const showLines = showLinesProp === undefined ? Children.count(children) > 1 : showLinesProp;

    const slots = calloutVariants({ showLines });

    return (
      <div
        ref={ref}
        className={slots.base({ className: cn(className, classNames?.base) })}
        {...props}
      >
        <CalloutProvider value={{ slots, classNames }}>{children}</CalloutProvider>
      </div>
    );
  }
) as ForwardRefComponentWithSubcomponents<
  HTMLDivElement,
  {
    Content: typeof CalloutContent;
  },
  CalloutProps
>;
Callout.displayName = "Callout";

export interface CalloutContentProps extends Omit<ComponentPropsWithoutRef<"div">, "title"> {
  icon: ReactNode;
  title: ReactNode;
}

const CalloutContent = forwardRef<HTMLDivElement, CalloutContentProps>(
  ({ className, icon, title, ...props }, ref) => {
    const { slots, classNames } = useCalloutContext();

    return (
      <div
        ref={ref}
        className={slots.content({ className: cn(className, classNames?.content) })}
        {...props}
      >
        {icon && <div className={slots.icon({ className: classNames?.icon })}>{icon}</div>}
        <div className={slots.title({ className: classNames?.title })}>{title}</div>
        <Divider orientation="vertical" className={slots.line({ className: classNames?.line })} />
        <div className={slots.description({ className: classNames?.description })}>
          {props.children}
        </div>
      </div>
    );
  }
);
CalloutContent.displayName = "Callout.Content";
Callout.Content = CalloutContent;
