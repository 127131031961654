import { isString } from "lodash";
import { Children, cloneElement, forwardRef, useMemo } from "react";
import { chain, mergeProps, useBreadcrumbItem, useBreadcrumbs, useFocusRing } from "react-aria";
import { tv } from "tailwind-variants";

import { useDomRef } from "../../hooks/useDomRef";
import { pickChildren } from "../../utils/children";
import { cn } from "../../utils/cn";
import { filterDOMProps } from "../../utils/filterDomProps";
import { dataAttr } from "../../utils/misc";
import { dataFocusVisible } from "../../utils/tailwindClasses";

import type { ComponentPropsWithoutRef, ElementType, ReactElement, ReactNode } from "react";
import type { AriaBreadcrumbItemProps, AriaBreadcrumbsProps, Key } from "react-aria";
import type { VariantProps } from "tailwind-variants";
import type { ForwardRefComponentWithSubcomponents, SlotsToClasses } from "../../utils/types";

const breadcrumbsVariants = tv({
  slots: {
    base: "",
    list: "mb-0 flex list-none flex-wrap ps-0",
    ellipsis: "",
    separator: "px-2 text-gray-400 dark:text-gray-400",
  },
});

const breadcrumbItemVariants = tv({
  slots: {
    base: "flex items-center",
    item: [
      "flex",
      "gap-1",
      "items-center",
      "font-normal",
      "whitespace-nowrap",
      "line-clamp-1",
      "outline-none",
      "tap-highlight-transparent",
      "transition-colors",
      ...dataFocusVisible,
    ],
    separator: "px-2 text-gray-400 dark:text-gray-400",
  },
  variants: {
    isCurrent: {
      false: {
        item: [
          "cursor-pointer",
          "text-gray-400",
          "hover:text-gray-300",
          "dark:text-gray-300",
          "dark:hover:text-gray-400",
        ],
      },
      true: {
        item: "cursor-default text-gray-850 hover:text-gray-850 dark:text-white dark:hover:text-white",
      },
    },
  },
});

export interface BreadcrumbsProps
  extends Omit<ComponentPropsWithoutRef<"nav">, keyof AriaBreadcrumbsProps>,
    AriaBreadcrumbsProps,
    VariantProps<typeof breadcrumbsVariants> {
  /** Custom separator element to display between the breadcrumbs */
  separator?: ReactNode;
  /** Maximum number of breadcrumbs to display before collapsing some and showing an ellipsis */
  maxItems?: number;
  /** If some items are hidden, how many to show before the ellipsis */
  itemsBeforeEllipsis?: number;
  /** If some items are hidden, how many to show after the ellipsis */
  itemsAfterEllipsis?: number;
  /** Render function for the ellipsis */
  renderEllipsis?: () => ReactNode;
  /** Classes to apply to the slots */
  classNames?: SlotsToClasses<keyof ReturnType<typeof breadcrumbsVariants>>;
  /** Callback for when a breadcrumb item is clicked */
  onAction?: (key: Key) => void;
}

export const Breadcrumbs = forwardRef<HTMLElement, BreadcrumbsProps>(
  (
    {
      children: childrenProp,
      className,
      classNames,
      separator = "/",
      maxItems = 8,
      itemsBeforeEllipsis = 1,
      itemsAfterEllipsis = 2,
      renderEllipsis,
      onAction,
      ...props
    },
    ref
  ) => {
    const { navProps } = useBreadcrumbs(props);

    const slots = useMemo(() => breadcrumbsVariants(), []);

    const [, children] = pickChildren(childrenProp, BreadcrumbItem);

    const childCount = Children.count(children);

    const content = useMemo(() => {
      const items = (children as ReactElement<BreadcrumbItemProps>[] | undefined)?.map(
        (child, index) => {
          const isLast = index === childCount - 1;
          const itemKey = child?.key || index;

          return cloneElement(child, {
            isLast,
            separator,
            isCurrent: isLast || child.props.isCurrent,
            key: itemKey,
            onPress: chain(child.props.onPress, () => onAction?.(itemKey)),
          });
        }
      );

      if (!items) return null;

      // TODO(sam): Implement ellipsis functionality - will need a dropdown menu that supports links
      // For now we will just ignore the relevant props and show all items

      // if (itemsBeforeEllipsis + itemsAfterEllipsis >= childCount) {
      //   console.warn("Breadcrumbs: itemsBeforeEllipsis + itemsAfterEllipsis must be less than the total number of items");
      //   return items;
      // }

      // if (childCount <= maxItems) return items;

      // const collapsedItems = items.slice(itemsBeforeEllipsis, items.length - itemsAfterEllipsis);

      // if (collapsedItems.length < 1) {
      //   return items;
      // }

      // const ellipsisItem = ...;

      // return [
      //   items.slice(0, itemsBeforeEllipsis),
      //   ellipsisItem,
      //   items.slice(items.length - itemsAfterEllipsis)
      // ];
      return items;
    }, [childCount, children, separator, onAction]);

    const domRef = useDomRef(ref);

    const baseProps = {
      ref: domRef,
      "data-slot": "base",
      className: slots.base({ className: cn(className, classNames?.base) }),
      ...mergeProps(navProps, filterDOMProps(props)),
    };

    const listProps = {
      "data-slot": "list",
      className: slots.list({ className: classNames?.list }),
    };

    return (
      <nav {...baseProps}>
        <ol {...listProps}>{content}</ol>
      </nav>
    );
  }
) as ForwardRefComponentWithSubcomponents<
  HTMLDivElement,
  {
    Item: typeof BreadcrumbItem;
  },
  BreadcrumbsProps
>;
Breadcrumbs.displayName = "Breadcrumbs";

export interface BreadcrumbItemProps
  extends Omit<ComponentPropsWithoutRef<"li">, keyof AriaBreadcrumbItemProps>,
    AriaBreadcrumbItemProps,
    VariantProps<typeof breadcrumbItemVariants> {
  separator?: ReactNode;
  isLast?: boolean;
  startContent?: ReactNode;
  endContent?: ReactNode;
  classNames?: SlotsToClasses<keyof ReturnType<typeof breadcrumbItemVariants>>;
  as?: ElementType;
}

const BreadcrumbItem = forwardRef<HTMLLIElement, BreadcrumbItemProps>(
  (
    {
      className,
      classNames,
      isLast,
      isCurrent = false,
      href,
      separator = "/",
      as: Component = href && !isCurrent ? "a" : "span",
      ...props
    },
    ref
  ) => {
    const domRef = useDomRef(ref);

    const { itemProps: ariaItemProps } = useBreadcrumbItem(
      { ...props, isCurrent, href, elementType: isString(Component) ? Component : "a" },
      domRef
    );

    const { isFocusVisible, isFocused, focusProps } = useFocusRing();

    const slots = useMemo(() => breadcrumbItemVariants({ isCurrent }), [isCurrent]);

    const baseProps = {
      ref: domRef,
      "data-slot": "base",
      className: slots.base({ className: cn(className, classNames?.base) }),
      ...filterDOMProps(props, { enabled: isString(Component) }),
    };

    const itemProps = {
      href: !isCurrent ? href : undefined,
      "data-slot": "item",
      "data-focus": dataAttr(isFocused),
      "data-focus-visible": dataAttr(isFocusVisible),
      "data-disabled": props.isDisabled,
      "data-current": isCurrent,
      className: slots.item({ className: classNames?.item }),
      ...mergeProps(ariaItemProps, props.isDisabled ? {} : focusProps),
    };

    const separatorProps = {
      "data-slot": "separator",
      "aria-hidden": true,
      className: slots.separator({ className: classNames?.separator }),
    };

    return (
      <li {...baseProps}>
        <Component {...itemProps}>
          {props.startContent}
          {props.children}
          {props.endContent}
        </Component>
        {!isLast && <span {...separatorProps}>{separator}</span>}
      </li>
    );
  }
);
BreadcrumbItem.displayName = "BreadcrumbItem";
Breadcrumbs.Item = BreadcrumbItem;
