import { Children, isValidElement } from "react";
import { isFragment } from "react-is";

import type { PropsWithChildren, ReactElement, ReactNode } from "react";

/**
 * Picks children of the desired component type from a React children array.
 */
export const pickChildren = <T = ReactNode>(
  children: T | undefined,
  targetChild: React.ElementType
): [T[] | undefined, T[] | undefined] => {
  const target: T[] = [];

  const nonMatchingChildren = Children.map(children, item => {
    if (!isValidElement(item)) return item;
    if (item.type === targetChild) {
      target.push(item as T);

      return null;
    }

    return item;
  })?.filter(Boolean);

  const matchingChildren = target.length >= 0 ? target : undefined;

  return [nonMatchingChildren, matchingChildren];
};

export const childrenToArray = (children: ReactNode) => {
  const returnArray: ReactElement[] = [];

  Children.forEach(children, child => {
    if (!isValidElement(child)) return;
    if (Array.isArray(child)) {
      returnArray.push(...childrenToArray(child));
    } else if (isFragment(child) && child.props) {
      returnArray.push(...childrenToArray((child.props as PropsWithChildren).children));
    } else {
      returnArray.push(child);
    }
  });

  return returnArray;
};
