import { OverlayContainer, useOverlay, useOverlayPosition } from "@react-aria/overlays";
import { useTooltip as useReactAriaTooltip, useTooltipTrigger } from "@react-aria/tooltip";
import { mergeProps } from "@react-aria/utils";
import { useTooltipTriggerState } from "@react-stately/tooltip";
import { AnimatePresence, motion } from "framer-motion";
import {
  Children,
  cloneElement,
  forwardRef,
  useCallback,
  useId,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";

import { useDomRef } from "../../hooks/useDomRef";
import { useElementOverflowing } from "../../hooks/useElementOverflowing";
import { cn } from "../../utils/cn";
import { getArrowPlacement, getTransformOrigins, toReactAriaPlacement } from "../../utils/overlay";
import { mergeRefs } from "../../utils/refs";
import { scaleSpring } from "../../utils/transitionVariants";
import { popoverVariants } from "../popover/usePopover";

import type { AriaOverlayProps } from "@react-aria/overlays";
import type { OverlayTriggerProps } from "@react-types/overlays";
import type { AriaTooltipProps } from "@react-types/tooltip";
import type { HTMLMotionProps } from "framer-motion";
import type { HTMLAttributes, ReactNode, Ref } from "react";
import type { OverlayOptions } from "../../utils/overlay";
import type { ReactRef, SlotsToClasses } from "../../utils/types";
import type { PopoverVariantProps } from "../popover/usePopover";

interface BaseProps extends Omit<HTMLAttributes<HTMLDivElement>, "content"> {
  /**
   * Ref to the DOM node.
   */
  ref?: ReactRef<HTMLElement | null>;
  /**
   * The children to render. Usually a trigger element.
   */
  children?: ReactNode;
  /**
   * The content of the tooltip.
   */
  content?: string | React.ReactNode;
  /**
   * Whether the tooltip should be disabled, independent from the trigger.
   */
  isDisabled?: boolean;
  /**
   * The delay time in ms for the tooltip to show up.
   * @default 0
   */
  delay?: number;
  /**
   * The delay time in ms for the tooltip to hide.
   * @default 500
   */
  closeDelay?: number;
  /**
   * By default, opens for both focus and hover. Can be made to open only for focus.
   */
  trigger?: "focus";
  /**
   * The props to modify the framer motion animation. Use the `variants` API to create your own animation.
   */
  motionProps?: HTMLMotionProps<"div">;
  /**
   * The container element in which the overlay portal will be placed.
   * @default document.body
   */
  portalContainer?: Element;
  /**
   * List of dependencies to update the position of the tooltip.
   * @default []
   */
  updatePositionDeps?: any[];
  /**
   * Classname or List of classes to change the classNames of the element.
   * if `className` is passed, it will be added to the base slot.
   *
   * @example
   * ```ts
   * <Tooltip classNames={{
   *    base:"base-classes",
   *    content: "content-classes",
   *    arrow: "arrow-classes",
   * }} />
   * ```
   */
  classNames?: SlotsToClasses<"base" | "arrow" | "content">;
}

export type TooltipProps = BaseProps &
  AriaTooltipProps &
  AriaOverlayProps &
  OverlayTriggerProps &
  OverlayOptions &
  PopoverVariantProps;

/**
 * A Tooltip displays information about a specific element when the user hovers over, focuses on,
 * or touches the element.
 */
export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>((props, ref) => {
  const {
    isOpen: isOpenProp,
    content,
    children,
    defaultOpen,
    onOpenChange,
    isDisabled,
    trigger: triggerAction,
    shouldFlip = true,
    containerPadding = 12,
    placement: placementProp = "top",
    delay = 0,
    closeDelay = 500,
    showArrow = false,
    offset = 7,
    crossOffset = 0,
    isDismissable,
    shouldCloseOnBlur = true,
    portalContainer,
    isKeyboardDismissDisabled = false,
    updatePositionDeps = [],
    shouldCloseOnInteractOutside,
    className,
    onClose,
    motionProps,
    classNames,
    size,
    color = "inverted",
    backdrop,
    triggerScaleOnOpen,
    disableAnimation,
    ...otherProps
  } = props;

  const state = useTooltipTriggerState({
    delay,
    closeDelay,
    isDisabled,
    defaultOpen,
    isOpen: isOpenProp,
    onOpenChange: isOpen => {
      onOpenChange?.(isOpen);
      if (!isOpen) {
        onClose?.();
      }
    },
  });

  const triggerRef = useRef<HTMLElement>(null);
  const overlayRef = useDomRef(ref);

  const tooltipId = useId();

  const isOpen = state.isOpen && !isDisabled;

  const { triggerProps, tooltipProps: triggerTooltipProps } = useTooltipTrigger(
    {
      isDisabled,
      trigger: triggerAction,
    },
    state,
    triggerRef
  );

  const { tooltipProps: ariaTooltipProps } = useReactAriaTooltip(
    {
      isOpen,
      ...mergeProps(props, triggerTooltipProps),
    },
    state
  );

  const {
    overlayProps: positionProps,
    placement,
    updatePosition,
  } = useOverlayPosition({
    isOpen: isOpen,
    targetRef: triggerRef,
    placement: toReactAriaPlacement(placementProp),
    overlayRef,
    offset: showArrow ? offset + 3 : offset,
    crossOffset,
    shouldFlip,
    containerPadding,
  });

  useLayoutEffect(() => {
    if (!updatePositionDeps.length) return;
    // force update position when deps change
    updatePosition();
  }, updatePositionDeps);

  const { overlayProps } = useOverlay(
    {
      isOpen: isOpen,
      onClose: state.close,
      isDismissable,
      shouldCloseOnBlur,
      isKeyboardDismissDisabled,
      shouldCloseOnInteractOutside,
    },
    overlayRef
  );

  const slots = useMemo(
    () =>
      popoverVariants({
        size,
        color,
        backdrop,
        triggerScaleOnOpen,
        disableAnimation,
      }),
    [size, color, backdrop, triggerScaleOnOpen, disableAnimation]
  );

  const getTriggerProps = useCallback(
    (props = {}, _ref: Ref<any> | null | undefined = null) => ({
      ...mergeProps(triggerProps, props),
      ref: mergeRefs(_ref, triggerRef),
      "aria-describedby": isOpen ? tooltipId : undefined,
    }),
    [triggerProps, isOpen, tooltipId, state]
  );

  const tooltipProps = useMemo(
    () => ({
      ref: overlayRef,
      "data-slot": "base",
      "data-open": isOpen,
      "data-arrow": showArrow,
      "data-disabled": isDisabled,
      "data-placement": getArrowPlacement(placement!, placementProp),
      ...mergeProps(ariaTooltipProps, overlayProps, otherProps),
      style: mergeProps(positionProps.style, otherProps.style, props.style),
      className: slots.base({ class: classNames?.base }),
      id: tooltipId,
    }),
    [
      slots,
      isOpen,
      showArrow,
      isDisabled,
      placement,
      placementProp,
      ariaTooltipProps,
      overlayProps,
      otherProps,
      positionProps,
      props,
      tooltipId,
      classNames?.base,
      overlayRef,
    ]
  );

  const tooltipContentProps = useMemo(
    () => ({
      "data-slot": "content",
      "data-open": isOpen,
      "data-arrow": showArrow,
      "data-disabled": isDisabled,
      "data-placement": getArrowPlacement(placement!, placementProp),
      className: slots.content({ class: cn(classNames?.content, className) }),
    }),
    [slots, isOpen, showArrow, isDisabled, placement, placementProp, classNames, className]
  );

  let trigger: React.ReactElement;

  try {
    /**
     * Ensure tooltip has only one child node
     */
    const child = Children.only(children) as React.ReactElement & {
      ref?: React.Ref<any>;
    };

    trigger = cloneElement(child, getTriggerProps(child.props, child.ref));
  } catch (error) {
    trigger = <span />;
    console.warn("Tooltip must have only one child node.");
  }

  const { ref: _, id, style, ...otherTooltipProps } = tooltipProps;

  const animatedContent = (
    <div ref={overlayRef} id={id} style={style}>
      <motion.div
        animate="enter"
        exit="exit"
        initial="exit"
        variants={scaleSpring}
        {...mergeProps(motionProps, otherTooltipProps)}
        style={{
          ...getTransformOrigins(placementProp),
        }}
      >
        <div {...tooltipContentProps}>{content}</div>
      </motion.div>
    </div>
  );

  return (
    <>
      {trigger}
      {disableAnimation && isOpen ? (
        <OverlayContainer portalContainer={portalContainer}>
          <div ref={overlayRef} id={id} style={style} {...otherTooltipProps}>
            <div {...tooltipContentProps}>{content}</div>
          </div>
        </OverlayContainer>
      ) : (
        <AnimatePresence>
          {isOpen ? (
            <OverlayContainer portalContainer={portalContainer}>{animatedContent}</OverlayContainer>
          ) : null}
        </AnimatePresence>
      )}
    </>
  );
});

Tooltip.displayName = "Tooltip";

export type TooltipOverflowTextProps = HTMLAttributes<HTMLDivElement>;

export const TooltipOverflowText = forwardRef<HTMLDivElement, TooltipOverflowTextProps>(
  ({ className, ...props }, ref) => {
    const contentRef = useRef<HTMLSpanElement>(null);
    const textOverflowing = useElementOverflowing(contentRef);
    const element = <span className="truncate" ref={contentRef} {...props} />;
    return (
      <Tooltip isDisabled={!textOverflowing} content={element} ref={ref}>
        {element}
      </Tooltip>
    );
  }
);
TooltipOverflowText.displayName = "TooltipOverflowText";
