import { useControlledState } from "@react-stately/utils";
import { useMemo, useRef, useState } from "react";
import { tv } from "tailwind-variants";

import { useDomRef } from "../../hooks/useDomRef";
import { cn } from "../../utils/cn";

import type { ComponentPropsWithoutRef, RefObject } from "react";
import type { VariantProps } from "tailwind-variants";
import type { SlotsToClasses } from "../../utils/types";
import type { ButtonProps } from "../button/Button";
import type { InputProps } from "../input/Input";
import type { HeadingProps } from "../typography/Heading";
import type { TextProps } from "../typography/Text";

const editableTextVariants = tv({
  slots: {
    base: "flex items-center gap-1",
    text: [
      "inline-flex",
      "items-center",
      "px-0.5",
      "py-px",
      "box-border",
      "cursor-pointer",
      "rounded",
      "border",
      "border-solid",
      "border-transparent",
      "transition-colors",
      "hover:border-gray-100",
      "dark:hover:border-gray-700",
    ],
    input: "",
    inputWrapper: "px-0",
    button: "shrink-0 hover:bg-transparent hover:opacity-75 dark:hover:bg-transparent",
  },
  variants: {
    bold: {
      true: {
        input: "font-semibold",
      },
    },
    size: {
      // Text only
      sm: {
        input: "text-xs",
        inputWrapper: "h-4 min-h-4",
        text: "h-4 min-h-4",
        button: "size-4 text-xs",
      },
      // Text and Heading (levels 5/6)
      md: {
        input: "text-sm",
        inputWrapper: "h-5 min-h-5",
        text: "h-5 min-h-5",
        button: "size-5",
      },
      lg: {
        input: "text-base",
        inputWrapper: "h-6 min-h-6",
        text: "h-6 min-h-6",
        button: "size-6",
      },
      // Heading only (levels 1-4)
      xl: {
        input: "text-xl",
        inputWrapper: "h-7 min-h-7",
        text: "h-7 min-h-7",
        button: "size-7 text-base",
      },
      "2xl": {
        input: "text-2xl",
        inputWrapper: "h-8 min-h-8",
        text: "h-8 min-h-8",
        button: "text-base",
      },
      "3xl": {
        input: "text-3xl",
        inputWrapper: "h-9 min-h-9",
        text: "h-9 min-h-9",
        button: "text-lg",
      },
      "4xl": {
        input: "text-4xl",
        inputWrapper: "h-10 min-h-10",
        text: "h-10 min-h-10",
        button: "text-xl",
      },
    },
  },
  defaultVariants: {
    size: "md",
  },
});

type BaseProps = {
  /** Whether the text is currently being edited */
  isEditing?: boolean;
  /** Callback to set the editing state */
  setIsEditing?: (editing: boolean) => void;
  /** The default value of the text */
  defaultValue?: string;
  /** The current value of the text */
  value?: string;
  /** Callback to set the value of the text */
  onValueChange?: (value: string) => void;
  /** Additional props to pass to the input */
  inputProps?: InputProps;
  /** Additional props to pass to the button */
  buttonProps?: ButtonProps;
  /** Additional props to pass to the text */
  textProps?: TextProps;
  /** Ref to the input element */
  inputRef?: React.RefObject<HTMLInputElement>;
  /** Optional classnames to pass to slots */
  classNames?: SlotsToClasses<keyof ReturnType<typeof editableTextVariants>>;
};

export type UseEditableTextProps = ComponentPropsWithoutRef<"div"> &
  BaseProps &
  VariantProps<typeof editableTextVariants>;

export function useEditableText({
  className,
  classNames,
  children,
  isEditing: isEditingProp,
  setIsEditing: setIsEditingProp,
  value = typeof children === "string" ? children : undefined,
  defaultValue = "",
  onValueChange,
  inputProps: inputPropsProp,
  buttonProps: buttonPropsProp,
  textProps: textPropsProp,
  inputRef,
  size,
  bold,
  ...props
}: UseEditableTextProps = {}) {
  const slots = useMemo(() => editableTextVariants({ size, bold }), [size, bold]);

  const [isEditing, setIsEditing] = useControlledState(isEditingProp, false, setIsEditingProp);
  const [textValue, setTextValue] = useControlledState(value, defaultValue, onValueChange);

  const [draftValue, setDraftValue] = useState(textValue);

  const domRef = useDomRef(inputRef);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);

  const baseProps = {
    ...props,
    className: slots.base({ className: cn(className, classNames?.base) }),
  };

  const inputProps: InputProps = {
    ...inputPropsProp,
    ref: domRef,
    autoFocus: true,
    value: draftValue,
    onValueChange: setDraftValue,
    classNames: {
      ...inputPropsProp?.classNames,
      input: slots.input({ className: cn(classNames?.input, inputPropsProp?.classNames?.input) }),
      inputWrapper: slots.inputWrapper({
        className: cn(classNames?.inputWrapper, inputPropsProp?.classNames?.inputWrapper),
      }),
    },
    onBlur: e => {
      if (e.relatedTarget !== cancelButtonRef.current) {
        setTextValue(draftValue);
        setIsEditing(false);
      }
      inputPropsProp?.onBlur?.(e);
    },
    onKeyDown: e => {
      if (e.key === "Escape") {
        setDraftValue(textValue);
        setIsEditing(false);
      } else if (e.key === "Enter") {
        setTextValue(draftValue);
        setIsEditing(false);
      }
      inputPropsProp?.onKeyDown?.(e);
    },
  };

  const baseButtonProps: ButtonProps = {
    ...buttonPropsProp,
    variant: "link",
    ghost: true,
    isIconOnly: true,
    className: slots.button({ className: cn(classNames?.button, buttonPropsProp?.className) }),
  };

  const editButtonProps: ButtonProps = {
    ...baseButtonProps,
    "aria-label": "Edit",
    onClick: () => setIsEditing(true),
  };

  const confirmButtonProps: ButtonProps = {
    ...baseButtonProps,
    "aria-label": "Confirm",
    onClick: () => {
      setTextValue(draftValue);
      setIsEditing(false);
    },
  };

  const cancelButtonProps: ButtonProps & { ref: RefObject<HTMLButtonElement> } = {
    ...baseButtonProps,
    ref: cancelButtonRef,
    "aria-label": "Cancel",
    variant: "light",
    onClick: () => {
      setDraftValue(textValue);
      setIsEditing(false);
    },
  };

  const textProps: TextProps | HeadingProps = {
    ...textPropsProp,
    className: slots.text({ className: cn(classNames?.text, textPropsProp?.className) }),
    onClick: () => setIsEditing(true),
  };

  return {
    isEditing,
    textValue,
    baseProps,
    inputProps,
    editButtonProps,
    confirmButtonProps,
    cancelButtonProps,
    textProps,
  };
}
