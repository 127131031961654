import { forwardRef } from "react";
import { tv } from "tailwind-variants";

import { EditableHeading } from "./EditableHeading";

import type { HTMLAttributes } from "react";
import type { VariantProps } from "tailwind-variants";
import type { ForwardRefComponentWithSubcomponents } from "../../utils/types";

export const headingVariants = tv({
  base: "m-0 whitespace-nowrap font-semibold text-gray-850 dark:text-white",
  variants: {
    level: {
      1: "text-4xl",
      2: "text-3xl",
      3: "text-2xl",
      4: "text-xl",
      5: "text-base",
      6: "text-sm",
    },
  },
  defaultVariants: {
    level: 4,
  },
});

export interface HeadingProps
  extends HTMLAttributes<HTMLHeadingElement>,
    VariantProps<typeof headingVariants> {}

export const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ className, level = 3, ...props }, ref) => {
    const Component = `h${level}` as const;

    return <Component ref={ref} className={headingVariants({ className, level })} {...props} />;
  }
) as ForwardRefComponentWithSubcomponents<
  HTMLHeadingElement,
  {
    Editable: typeof EditableHeading;
  },
  HeadingProps
>;
Heading.displayName = "Heading";
Heading.Editable = EditableHeading;
