import { useImperativeHandle, useRef } from "react";

import type { Ref, RefObject } from "react";

export const useDomRef = <T extends HTMLElement = HTMLElement>(
  ref?: RefObject<T | null> | Ref<T | null>
) => {
  const domRef = useRef<T>(null);

  useImperativeHandle<T | null, T | null>(ref, () => domRef.current);

  return domRef;
};
