import { forwardRef } from "react";
import { tv } from "tailwind-variants";

import { cn } from "../../utils/cn";
import { Heading } from "../typography/Heading";
import { Text } from "../typography/Text";

import type { HTMLAttributes } from "react";
import type { VariantProps } from "tailwind-variants";
import type { ForwardRefComponentWithSubcomponents } from "../../utils/types";
import type { EditableHeadingProps } from "../typography/EditableHeading";

const pageHeaderVariants = tv({
  base: "mx-8 flex flex-col gap-3 pb-2",
  variants: {
    withDivider: {
      false: "",
      true: [
        "border-solid",
        "border-gray-50",
        "border-b",
        "border-t-0",
        "border-l-0",
        "border-r-0",
      ],
    },
    withBreadcrumbs: {
      false: "pt-8",
      true: "pt-6",
    },
  },
  defaultVariants: {
    withDivider: false,
  },
});

export interface PageHeaderProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof pageHeaderVariants> {}

export const PageHeader = forwardRef<HTMLDivElement, PageHeaderProps>(
  ({ className, withDivider, withBreadcrumbs, ...props }, ref) => {
    return (
      <div
        className={pageHeaderVariants({ withDivider, withBreadcrumbs, className })}
        ref={ref}
        {...props}
      />
    );
  }
) as ForwardRefComponentWithSubcomponents<
  HTMLDivElement,
  {
    Heading: typeof PageHeaderHeading;
    Content: typeof PageHeaderContent;
  },
  PageHeaderProps
>;
PageHeader.displayName = "PageHeader";

const PageHeaderHeading = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div className={cn("flex items-center justify-between", className)} ref={ref} {...props} />
    );
  }
) as ForwardRefComponentWithSubcomponents<
  HTMLDivElement,
  {
    Main: typeof PageHeaderHeadingMain;
    Title: typeof PageHeaderTitle;
    EditableTitle: typeof PageHeaderEditableTitle;
    Subtitle: typeof PageHeaderSubtitle;
    Extra: typeof PageHeaderExtra;
  }
>;
PageHeaderHeading.displayName = "PageHeader.Heading";
PageHeader.Heading = PageHeaderHeading;

const PageHeaderHeadingMain = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return <div className={cn("flex items-center gap-4", className)} ref={ref} {...props} />;
  }
);
PageHeaderHeadingMain.displayName = "PageHeader.Heading.Main";
PageHeaderHeading.Main = PageHeaderHeadingMain;

const PageHeaderTitle = forwardRef<HTMLHeadingElement, HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => {
    return (
      <Heading level={2} className={cn(className, "max-w-[600px] truncate")} {...props} ref={ref} />
    );
  }
);
PageHeaderTitle.displayName = "PageHeader.Heading.Title";
PageHeaderHeading.Title = PageHeaderTitle;

const PageHeaderEditableTitle = forwardRef<HTMLDivElement, EditableHeadingProps>((props, ref) => {
  return <Heading.Editable level={2} {...props} ref={ref} />;
});
PageHeaderEditableTitle.displayName = "PageHeader.Heading.EditableTitle";
PageHeaderHeading.EditableTitle = PageHeaderEditableTitle;

const PageHeaderSubtitle = forwardRef<HTMLSpanElement, HTMLAttributes<HTMLSpanElement>>(
  (props, ref) => {
    return <Text emphasis="tertiary" {...props} ref={ref} />;
  }
);
PageHeaderSubtitle.displayName = "PageHeader.Heading.Subtitle";
PageHeaderHeading.Subtitle = PageHeaderSubtitle;

const PageHeaderExtra = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return <div className={cn("ml-4 flex gap-2", className)} ref={ref} {...props} />;
  }
);
PageHeaderExtra.displayName = "PageHeader.Heading.Extra";
PageHeaderHeading.Extra = PageHeaderExtra;

const PageHeaderContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return <div className={cn("flex flex-col", className)} ref={ref} {...props} />;
  }
);
PageHeaderContent.displayName = "PageHeader.Content";
PageHeader.Content = PageHeaderContent;
