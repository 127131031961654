import { forwardRef, useMemo } from "react";
import { mergeProps, useFocusRing, useTableCell } from "react-aria";

import { useDomRef } from "../../../hooks/useDomRef";
import { cn } from "../../../utils/cn";

import type { GridNode } from "@react-types/grid";
import type { HTMLAttributes } from "react";
import type { Key, TableState } from "react-stately";
import type { TableCellProps } from "../TableCell";
import type { TableColumnProps } from "../TableColumn";
import type { TableClassNames, TableSlots } from "../tableVariants";

interface TableCellInternalProps<T = object> extends HTMLAttributes<HTMLTableCellElement> {
  rowKey: Key;
  node: GridNode<T>;
  state: TableState<T>;
  slots: TableSlots;
  classNames?: TableClassNames;
}

export const TableCell = forwardRef<HTMLTableCellElement, TableCellInternalProps>(
  ({ rowKey, node, state, className, slots, classNames, ...otherProps }, ref) => {
    const domRef = useDomRef(ref);

    const { gridCellProps } = useTableCell({ node }, state, domRef);
    const { isFocusVisible, focusProps } = useFocusRing();

    const isRowSelected = state.selectionManager.isSelected(rowKey);

    const cellProps: TableCellProps = node.props;
    const columnProps: TableColumnProps<unknown> = node.column?.props ?? {};

    const cell = useMemo(() => {
      const cellType = typeof node.rendered;

      return cellType !== "object" && cellType !== "function" ? (
        <span>{node.rendered}</span>
      ) : (
        node.rendered
      );
    }, [node.rendered]);

    return (
      <td
        ref={domRef}
        data-focus-visible={isFocusVisible}
        data-selected={isRowSelected}
        {...mergeProps(gridCellProps, focusProps, cellProps, otherProps)}
        className={slots.td({
          align: columnProps.align,
          className: cn(classNames?.td, className, cellProps.className),
        })}
      >
        {cell}
      </td>
    );
  }
);
TableCell.displayName = "Table.Cell";
