import { faCheck, faEdit, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef } from "react";

import { Button } from "../button/Button";
import { Input } from "../input/Input";
import { Text } from "../typography/Text";
import { useEditableText } from "./useEditableText";

import type { TextProps } from "../typography/Text";
import type { UseEditableTextProps } from "./useEditableText";

export type EditableTextProps = UseEditableTextProps & {
  size?: TextProps["size"];
};

export const EditableText = forwardRef<HTMLDivElement, EditableTextProps>((props, ref) => {
  const {
    isEditing,
    textValue,
    baseProps,
    inputProps,
    editButtonProps,
    confirmButtonProps,
    cancelButtonProps,
    textProps,
  } = useEditableText(props);

  return (
    <div ref={ref} {...baseProps}>
      {isEditing ? (
        <>
          <Input {...inputProps} />
          <Button key="confirm" {...confirmButtonProps}>
            <FontAwesomeIcon icon={faCheck} />
          </Button>
          <Button key="cancel" {...cancelButtonProps}>
            <FontAwesomeIcon icon={faXmark} />
          </Button>
        </>
      ) : (
        <>
          <Text {...textProps} size={props.size} bold={props.bold}>
            {textValue}
          </Text>
          <Button key="edit" {...editButtonProps}>
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        </>
      )}
    </div>
  );
});
EditableText.displayName = "Text.Editable";
