const path = require("path");
const colors = require("tailwindcss/colors");

/** @type {import('tailwindcss').Config} */
module.exports = {
  // Include packages here for Storybook.
  content: [
    path.resolve(__dirname, "./cloud/clients/customer_portal/public/index.html"),
    path.resolve(__dirname, "./cloud/clients/customer_portal/src/**/*.{js,jsx,ts,tsx}"),
    path.resolve(__dirname, "./cloud/web/rivet-ui/src/**/*.{js,jsx,ts,tsx}"),
    path.resolve(__dirname, "./cloud/web/rivet-entities/src/**/*.{js,jsx,ts,tsx}"),
    path.resolve(__dirname, "./cloud/web/shared-ui/**/*.{js,jsx,ts,tsx}"),
    path.resolve(__dirname, "./tools/streamlit/web_components/components/**/*.{js,ts,jsx,tsx}"),
    path.resolve(__dirname, "./tools/streamlit/web_components/pages/**/*.{js,ts,jsx,tsx}"),
  ],
  corePlugins: {
    // Don't break AntD global styles
    // See https://dev.to/fabiobiondi/react-antd-and-tailwind-fix-css-conflicts-5542
    preflight: false,
  },
  darkMode: ["class", '[data-theme="dark"]'],
  plugins: [
    require("@tailwindcss/aspect-ratio"),
    require("@tailwindcss/forms")({
      // Don't break AntD forms
      // See https://github.com/tailwindlabs/tailwindcss-forms#using-only-global-styles-or-only-classes
      strategy: "class",
    }),
    require("tailwindcss-animate"),
    require("@tailwindcss/container-queries"),
    // Allow to edit scrollbar styling with tailwind.
    // No compatible mode enables to set the width/height and radius of scrollbars, but won't have
    // effect on non-chromium browsers (but won't break things neither)
    require("tailwind-scrollbar")({ nocompatible: true }),
    // Add all colors from the Tailwind theme palette as CSS variables
    // (To reuse them in places where we have to use CSS, like our custom CSS for Dockview)
    function ({ addBase, theme }) {
      const colors = theme("colors");
      const cssVariables = {};
      Object.keys(colors).forEach(color => {
        if (typeof colors[color] === "string") {
          cssVariables[`--tw-${color}`] = colors[color];
        } else {
          Object.keys(colors[color]).forEach(shade => {
            cssVariables[`--tw-${color}-${shade}`] = colors[color][shade];
          });
        }
      });
      addBase({
        ":root": cssVariables,
      });
    },
    // Add a utility class to hide scrollbars
    function ({ addUtilities }) {
      addUtilities({
        ".scrollbar-hide": {
          /* IE and Edge */
          "-ms-overflow-style": "none",
          /* Firefox */
          "scrollbar-width": "none",
          /* Safari and Chrome */
          "&::-webkit-scrollbar": {
            display: "none",
          },
        },
      });
    },
  ],
  theme: {
    colors: {
      transparent: "transparent",
      current: "currentColor",
      white: colors.white,
      black: colors.black,
      gray: {
        DEFAULT: "#808080",
        20: "#FAFAFA",
        50: "#F1F1F1",
        100: "#E6E6E6",
        150: "#D9D9D9",
        200: "#CCCCCC",
        300: "#B3B3B3",
        400: "#999999",
        500: "#808080",
        600: "#666666",
        700: "#4D4D4D",
        800: "#333333",
        850: "#262626",
        900: "#1A1A1A",
      },
      blue: {
        DEFAULT: "#0077EE",
        50: "#F0F7FF",
        100: "#E5F2FF",
        150: "#CCE6FF",
        200: "#99CCFF",
        300: "#66B3FF",
        400: "#2F8EED",
        500: "#0077EE",
        600: "#0066CC",
        700: "#004D99",
        800: "#003366",
        900: "#001A33",
      },
      red: {
        DEFAULT: "#D9262F",
        50: "#FBE8E8",
        100: "#FDCECE",
        200: "#FA9E9E",
        300: "#F76E6E",
        400: "#F53D3D",
        500: "#D9262F",
        600: "#AE1E26",
        700: "#82171C",
        800: "#5C0A0E",
        900: "#330003",
      },
      yellow: {
        DEFAULT: "#FFB700",
        50: "#FFF9EB",
        100: "#FFF0CC",
        200: "#FFE299",
        300: "#FFD366",
        400: "#FFC433",
        500: "#FFB700",
        600: "#CC9200",
        700: "#996E00",
        800: "#664900",
        900: "#332500",
      },
      // TODO(sam, jenn): finalize green colors
      green: {
        DEFAULT: "#22C55E",
        50: "#F0FDF5",
        100: "#DCFCE8",
        200: "#BBF7D1",
        300: "#8DEFAD",
        400: "#4ADE80",
        500: "#22C55E",
        600: "#16A34A",
        700: "#15803C",
        800: "#166533",
        900: "#14532B",
      },
      // NOTE(vincent): Added magenta and cyan colors, needed to port existing things
      // (such as the "BETA" badge) from antd to rivet UI
      magenta: {
        DEFAULT: "#a855f7",
        50: "#faf5ff",
        100: "#f3e8ff",
        200: "#e9d5ff",
        300: "#d8b4fe",
        400: "#c084fc",
        500: "#a855f7",
        600: "#9333ea",
        700: "#7e22ce",
        800: "#6b21a8",
        900: "#581c87",
        950: "#3b0764",
      },
      cyan: {
        DEFAULT: "#06b6d4",
        50: "#ecfeff",
        100: "#cffafe",
        200: "#a5f3fc",
        300: "#67e8f9",
        400: "#22d3ee",
        500: "#06b6d4",
        600: "#0891b2",
        700: "#0e7490",
        800: "#155e75",
        900: "#164e63",
        950: "#083344",
      },
      // NOTE(sam): These legacy colors are deprecated and should not be used in new code.
      // These are included here as a temporary measure for the sake of not breaking any UI but
      // should be removed and replaced with the official colors above.
      legacyGray: {
        50: colors.gray[50],
        100: colors.gray[100],
        200: colors.gray[200],
        300: colors.gray[300],
        400: colors.gray[400],
        500: colors.gray[500],
        600: colors.gray[600],
        700: colors.gray[700],
        800: colors.gray[800],
        900: colors.gray[900],
      },
      legacySlate: {
        100: colors.slate[100],
        200: colors.slate[200],
        800: colors.slate[800],
      },
      legacyZinc: {
        500: colors.zinc[500],
      },
      legacyEmerald: {
        500: colors.emerald[500],
        600: colors.emerald[600],
        700: colors.emerald[700],
      },
      legacyViolet: {
        100: colors.violet[100],
        300: colors.violet[300],
        500: colors.violet[500],
        700: colors.violet[700],
        900: colors.violet[900],
      },
      legacyPurple: {
        400: colors.purple[400],
      },
      legacyPink: {
        400: colors.pink[400],
        500: colors.pink[500],
        700: colors.pink[700],
      },
    },
    fontFamily: {
      // Use the Skydio theme font
      // See https://tailwindcss.com/docs/theme
      sans: ["SF Pro Display", "sans-serif"],
      mono: ["ui-monospace", "monospace"],
    },
    extend: {
      keyframes: {
        "accordion-down": {
          from: { height: 0 },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: 0 },
        },
        "collapsible-closed": {
          from: { height: "var(--radix-collapsible-content-height)" },
          to: { height: 0 },
        },
        "collapsible-open": {
          from: { height: 0 },
          to: { height: "var(--radix-collapsible-content-height)" },
        },
        shimmer: {
          "100%": {
            transform: "translateX(100%)",
          },
        },
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
        "collapsible-closed": "collapsible-closed 0.2s ease-out",
        "collapsible-open": "collapsible-open 0.2s ease-out",
      },
      boxShadow: {
        // Use the Skydio theme shadow
        // See https://tailwindcss.com/docs/theme
        cardHover: "0 1px 2px -2px #00000029, 0 3px 6px #0000001f, 0 5px 12px 4px #00000017",
      },
      zIndex: {
        1: "1",
      },
      flex: {
        fixed: "0 0 auto",
      },
    },
  },
  variants: {
    extend: {
      visibility: ["group-hover"],
    },
  },
};
