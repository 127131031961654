import { forwardRef, useMemo } from "react";
import { tv } from "tailwind-variants";

import { cn } from "../../utils/cn";

import type { ComponentPropsWithoutRef, ReactNode } from "react";
import type { VariantProps } from "tailwind-variants";
import type { SlotsToClasses } from "../../utils/types";

export const badgeVariants = tv({
  slots: {
    base: "relative inline-flex shrink-0",
    badge: [
      "absolute",
      "z-10",
      "flex",
      "flex-wrap",
      "box-border",
      "rounded-full",
      "whitespace-nowrap",
      "place-content-center",
      "items-center",
      "text-inherit",
      "select-none",
      "origin-center",
      "scale-100",
      "opacity-100",
      "subpixel-antialiased",
      "transition",
      "data-[invisible=true]:scale-0",
      "data-[invisible=true]:opacity-0",
    ],
  },
  variants: {
    color: {
      primary: {
        badge: "bg-blue text-white",
      },
      success: {
        badge: "bg-green text-white dark:text-black",
      },
      warning: {
        badge: "bg-yellow text-black",
      },
      error: {
        badge: "bg-red text-white",
      },
    },
    size: {
      sm: {
        badge: "px-1 text-xs",
      },
      lg: {
        badge: "px-1 text-sm",
      },
    },
    placement: {
      "top-right": {
        badge: "-translate-y-1/2 translate-x-1/2",
      },
      "top-left": {
        badge: "-translate-x-1/2 -translate-y-1/2",
      },
      "bottom-right": {
        badge: "translate-x-1/2 translate-y-1/2",
      },
      "bottom-left": {
        badge: "-translate-x-1/2 translate-y-1/2",
      },
    },
    parentShape: {
      circle: {},
      rectangle: {},
    },
    isOneChar: {
      true: {
        badge: "px-0",
      },
    },
    isDot: {
      true: {
        badge: "px-0",
      },
    },
  },
  compoundVariants: [
    {
      isOneChar: true,
      size: "sm",
      class: {
        badge: "size-3.5 min-h-3 min-w-3.5",
      },
    },
    {
      isOneChar: true,
      size: "lg",
      class: {
        badge: "size-4 min-h-4 min-w-4",
      },
    },
    {
      isDot: true,
      size: "sm",
      class: {
        badge: "size-1.5 min-h-1.5 min-w-1.5",
      },
    },
    {
      isDot: true,
      size: "lg",
      class: {
        badge: "size-2.5 min-h-2.5 min-w-2.5",
      },
    },
    {
      placement: "top-right",
      parentShape: "rectangle",
      isDot: false,
      class: {
        badge: "right-[5%] top-1.5",
      },
    },
    {
      placement: "top-left",
      parentShape: "rectangle",
      isDot: false,
      class: {
        badge: "left-[5%] top-1.5",
      },
    },
    {
      placement: "bottom-right",
      parentShape: "rectangle",
      isDot: false,
      class: {
        badge: "bottom-1.5 right-[5%]",
      },
    },
    {
      placement: "bottom-left",
      parentShape: "rectangle",
      isDot: false,
      class: {
        badge: "bottom-1.5 left-[5%]",
      },
    },
    {
      placement: "top-right",
      parentShape: "rectangle",
      isDot: true,
      class: {
        badge: "right-0.5 top-0.5",
      },
    },
    {
      placement: "top-left",
      parentShape: "rectangle",
      isDot: true,
      class: {
        badge: "left-0.5 top-0.5",
      },
    },
    {
      placement: "bottom-right",
      parentShape: "rectangle",
      isDot: true,
      class: {
        badge: "bottom-0.5 right-0.5",
      },
    },
    {
      placement: "bottom-left",
      parentShape: "rectangle",
      isDot: true,
      class: {
        badge: "bottom-0.5 left-0.5",
      },
    },
    {
      placement: "top-right",
      parentShape: "circle",
      class: {
        badge: "right-[15%] top-[15%] ",
      },
    },
    {
      placement: "top-left",
      parentShape: "circle",
      class: {
        badge: "left-[15%] top-[15%]",
      },
    },
    {
      placement: "bottom-right",
      parentShape: "circle",
      class: {
        badge: "bottom-[15%] right-[15%]",
      },
    },
    {
      placement: "bottom-left",
      parentShape: "circle",
      class: {
        badge: "bottom-[15%] left-[15%]",
      },
    },
  ],
  defaultVariants: {
    color: "primary",
    size: "sm",
    placement: "top-right",
    parentShape: "rectangle",
  },
});

type BadgeClassNames = SlotsToClasses<keyof ReturnType<typeof badgeVariants>>;

export interface BadgeProps
  extends Omit<ComponentPropsWithoutRef<"span">, "color" | "content">,
    Omit<VariantProps<typeof badgeVariants>, "isDot"> {
  children: ReactNode;
  content?: ReactNode;
  isInvisible?: boolean;
  classNames?: BadgeClassNames;
}

export const isBadgeContentEmpty = (content: ReactNode = "") => String(content).length === 0;

export const Badge = forwardRef<HTMLSpanElement, BadgeProps>(
  (
    {
      className,
      children,
      content = "",
      color,
      size,
      placement,
      parentShape,
      isInvisible,
      classNames,
      isOneChar: isOneCharProp,
      ...props
    },
    ref
  ) => {
    const isOneChar = useMemo(
      () => String(content).length === 1 || !!isOneCharProp,
      [content, isOneCharProp]
    );

    const isDot = useMemo(() => isBadgeContentEmpty(content), [content]);

    const slots = useMemo(
      () => badgeVariants({ color, size, placement, isOneChar, isDot, parentShape }),
      [color, size, placement, isOneChar, isDot, parentShape]
    );

    return (
      <div className={slots.base({ className: classNames?.base })}>
        {children}
        <span
          ref={ref}
          className={slots.badge({ className: cn(className, classNames?.badge) })}
          data-invisible={isInvisible}
          {...props}
        >
          {content}
        </span>
      </div>
    );
  }
);
Badge.displayName = "Badge";
