import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useListBox } from "@react-aria/listbox";
import { mergeProps } from "@react-aria/utils";
import { useListState } from "@react-stately/list";
import { forwardRef, useMemo } from "react";
import { tv } from "tailwind-variants";

import { useDomRef } from "../../hooks/useDomRef";
import { ListBoxItem, ListBoxItemInternal } from "./ListBoxItem";
import { ListBoxSection, ListBoxSectionInternal } from "./ListBoxSection";

import type { AriaListBoxProps } from "@react-aria/listbox";
import type { ListState } from "@react-stately/list";
import type { ComponentPropsWithoutRef, ForwardedRef, JSX, ReactNode } from "react";
import type { VariantProps } from "tailwind-variants";
import type { ReactRef } from "../../utils/types";

const listbox = tv({
  slots: {
    wrapper: "relative flex w-full flex-col gap-1 p-1",
    list: "m-0 flex w-full flex-col gap-0.5 p-0 outline-none",
    emptyContent: "size-full px-2 py-1.5 text-start text-gray-400 dark:text-gray-500",
    loader:
      "flex w-full items-center justify-center py-1 text-gray-300 [--fa-animation-duration:0.5s] dark:text-gray-600",
  },
  variants: {
    isLoading: {
      false: {
        loader: "hidden",
      },
    },
  },
});

type ListBoxVariantProps = VariantProps<typeof listbox>;

export interface ListBoxProps<T>
  extends Omit<ComponentPropsWithoutRef<"ul">, "children" | "onBlur" | "onFocus" | "autoFocus">,
    AriaListBoxProps<T>,
    ListBoxVariantProps {
  state?: ListState<T>;
  /**
   *  Provides content to display when there are no items.
   * @default "No results."
   */
  emptyContent?: ReactNode;
  /**
   * Whether to not display the empty content when there are no items.
   * @default false
   */
  hideEmptyContent?: boolean;
  /**
   * Whether the listbox items should use virtual focus instead of being focused directly.
   */
  shouldUseVirtualFocus?: boolean;
  /** Whether selection should occur on press up instead of press down. */
  shouldSelectOnPressUp?: boolean;
  /** Whether options should be focused when the user hovers over them. */
  shouldFocusOnHover?: boolean;
  /** Whether the item should display the same "hover" styles as when it is focused. */
  shouldHighlightOnFocus?: boolean;

  isLoading?: boolean;

  loaderRef?: ReactRef<HTMLDivElement>;
}

const ListBoxImpl = <T extends object>(
  props: ListBoxProps<T>,
  ref: ForwardedRef<HTMLUListElement>
) => {
  const {
    className,
    children,
    state: propState,
    emptyContent: emptyContentProp = "No results.",
    hideEmptyContent = false,
    shouldHighlightOnFocus = false,
    shouldUseVirtualFocus = false,
    shouldSelectOnPressUp = false,
    shouldFocusOnHover = false,
    onAction,
    onSelectionChange,
    isLoading,
    loaderRef,
    autoFocus,
    ...otherProps
  } = props;

  const domRef = useDomRef(ref);
  const loadingDomRef = useDomRef(loaderRef);

  const innerState = useListState(props);
  const state = propState || innerState;

  const { listBoxProps } = useListBox(props, state, domRef);

  const slots = useMemo(() => listbox({ isLoading }), [isLoading]);

  const listProps = useMemo(
    () => ({
      ref: domRef,
      className: slots.list(),
      ...mergeProps(listBoxProps, otherProps),
    }),
    [domRef, slots, listBoxProps, otherProps]
  );

  return (
    <div className={slots.wrapper({ className })}>
      <ul {...listProps}>
        {!state.collection.size && (
          <li>
            <div className={slots.emptyContent()}>{emptyContentProp}</div>
          </li>
        )}
        {[...state.collection].map(item => {
          if (item.type === "section") {
            return (
              <ListBoxSectionInternal key={item.key} item={item} state={state} {...item.props} />
            );
          }
          let listBoxItem = (
            <ListBoxItemInternal key={item.key} item={item} state={state} {...item.props} />
          );

          if (item.wrapper) {
            listBoxItem = item.wrapper(listBoxItem);
          }

          return listBoxItem;
        })}
        <div ref={loadingDomRef} className={slots.loader()}>
          <FontAwesomeIcon icon={faSpinnerThird} spin size="lg" />
        </div>
      </ul>
    </div>
  );
};

const WrappedListBox = forwardRef(ListBoxImpl) as <T = object>(
  props: ListBoxProps<T> & { ref?: ForwardedRef<HTMLUListElement> }
) => JSX.Element;

/**
 * A container displaying a list of options that the user can select one or more of.
 *
 * ListBox follows the react-aria Collection Components API (https://react-spectrum.adobe.com/react-stately/collections.html).
 */
export const ListBox = WrappedListBox as typeof WrappedListBox & {
  displayName: string;
  Item: typeof ListBoxItem;
  Section: typeof ListBoxSection;
};
ListBox.displayName = "ListBox";
ListBox.Item = ListBoxItem;
ListBox.Section = ListBoxSection;
