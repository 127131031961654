import { getItemId, listData } from "@react-aria/listbox";
import { announce } from "@react-aria/live-announcer";
import { useMenuTrigger } from "@react-aria/menu";
import { ariaHideOutside } from "@react-aria/overlays";
import { ListKeyboardDelegate, useSelectableCollection } from "@react-aria/selection";
import { useTextField } from "@react-aria/textfield";
import { chain, isAppleDevice, mergeProps, useLabels, useRouter } from "@react-aria/utils";
import { getChildNodes, getItemCount } from "@react-stately/collections";
import { privateValidationStateProp } from "@react-stately/form";
import { useEffect, useMemo, useRef } from "react";

import { pluralize } from "../../utils/lang";

import type { AriaListBoxOptions } from "@react-aria/listbox";
import type { AriaButtonProps } from "@react-types/button";
import type {
  BaseEvent,
  DOMAttributes,
  KeyboardDelegate,
  PressEvent,
  ValidationResult,
} from "@react-types/shared";
import type { FocusEvent, InputHTMLAttributes, KeyboardEvent, RefObject, TouchEvent } from "react";
import type { AriaMultiSelectComboBoxProps } from "./types";
import type { MultiSelectComboBoxState } from "./useMultiSelectComboBoxState";

/**
 * NOTE(sam): This is adapted from useComboBox.ts in @react-aria/combobox, which only supports
 * single selection. Most of this code is the same with some small modifications to support
 * multiselect - the key differences in this file are the props/state types and some logic around
 * the tag group.
 */

export interface AriaMultiSelectComboBoxOptions<T>
  extends Omit<AriaMultiSelectComboBoxProps<T>, "children"> {
  /** The ref for the input element. */
  inputRef: RefObject<HTMLInputElement>;
  /** The ref for the list box popover. */
  popoverRef: RefObject<Element>;
  /** The ref for the list box. */
  listBoxRef: RefObject<HTMLElement>;
  /** The ref for the optional list box popup trigger button. */
  buttonRef?: RefObject<Element>;
  /* The ref for the tag group showing selected items. */
  tagGroupRef: RefObject<HTMLDivElement>;
  /** An optional keyboard delegate implementation, to override the default. */
  keyboardDelegate?: KeyboardDelegate;
}

export interface ComboBoxAria<T> extends ValidationResult {
  /** Props for the label element. */
  labelProps: DOMAttributes;
  /** Props for the combo box input element. */
  inputProps: InputHTMLAttributes<HTMLInputElement>;
  /** Props for the list box, to be passed to [useListBox](useListBox.html). */
  listBoxProps: AriaListBoxOptions<T>;
  /** Props for the optional trigger button, to be passed to [useButton](useButton.html). */
  buttonProps: AriaButtonProps;
  /** Props for the combo box description element, if any. */
  descriptionProps: DOMAttributes;
  /** Props for the combo box error message element, if any. */
  errorMessageProps: DOMAttributes;
}

/**
 * Provides the behavior and accessibility implementation for a combo box component.
 * A combo box combines a text input with a listbox, allowing users to filter a list of options to items matching a query.
 * @param props - Props for the combo box.
 * @param state - State for the select, as returned by `useComboBoxState`.
 */
export function useMultiSelectComboBox<T>(
  props: AriaMultiSelectComboBoxOptions<T>,
  state: MultiSelectComboBoxState<T>
): ComboBoxAria<T> {
  const {
    buttonRef,
    popoverRef,
    inputRef,
    listBoxRef,
    tagGroupRef,
    keyboardDelegate,
    shouldFocusWrap,
    isReadOnly,
    isDisabled,
  } = props;

  const { menuTriggerProps, menuProps } = useMenuTrigger<T>(
    {
      type: "listbox",
      isDisabled: isDisabled || isReadOnly,
    },
    state,
    buttonRef!
  );

  // Set listbox id so it can be used when calling getItemId later
  listData.set(state, { id: menuProps.id! });

  // By default, a KeyboardDelegate is provided which uses the DOM to query layout information (e.g. for page up/page down).
  // When virtualized, the layout object will be passed in as a prop and override this.
  const delegate = useMemo(
    () =>
      keyboardDelegate ||
      new ListKeyboardDelegate(state.collection, state.disabledKeys, listBoxRef),
    [keyboardDelegate, state.collection, state.disabledKeys, listBoxRef]
  );

  // Use useSelectableCollection to get the keyboard handlers to apply to the textfield
  const { collectionProps } = useSelectableCollection({
    selectionManager: state.selectionManager,
    keyboardDelegate: delegate,
    disallowTypeAhead: true,
    disallowEmptySelection: true,
    shouldFocusWrap,
    ref: inputRef,
    // Prevent item scroll behavior from being applied here, should be handled in the user's Popover + ListBox component
    isVirtualized: true,
  });

  const router = useRouter();

  // For textfield specific keydown operations
  const onKeyDown = (e: BaseEvent<KeyboardEvent<any>>) => {
    switch (e.key) {
      case "Enter":
      case "Tab":
        // Prevent form submission if menu is open since we may be selecting a option
        if (state.isOpen && e.key === "Enter") {
          e.preventDefault();
        }

        // If the focused item is a link, trigger opening it. Items that are links are not selectable.
        if (
          state.isOpen &&
          state.selectionManager.focusedKey != null &&
          state.selectionManager.isLink(state.selectionManager.focusedKey)
        ) {
          if (e.key === "Enter") {
            const item = listBoxRef.current?.querySelector(
              `[data-key="${state.selectionManager.focusedKey}"]`
            );
            if (item instanceof HTMLAnchorElement) {
              router.open(item, e, item.href, undefined);
            }
          }

          state.close();
        } else {
          state.commit();
        }
        break;
      case "Escape":
        if (state.selectedKeys.size || state.inputValue === "" || props.allowsCustomValue) {
          e.continuePropagation();
        }
        state.revert();
        break;
      case "ArrowDown":
        state.open("first", "manual");
        break;
      case "ArrowUp":
        state.open("last", "manual");
        break;
      case "ArrowLeft":
      case "ArrowRight":
        state.selectionManager.setFocusedKey(null);
        break;
      case "Backspace":
        // Delete the last option if in multiselect mode (only if the cursor is at the beginning of the input)
        if (
          state.selectionMode === "multiple" &&
          inputRef.current?.selectionStart === 0 &&
          inputRef.current?.selectionEnd === 0
        ) {
          const lastKey = [...state.selectedKeys].pop();
          if (lastKey) {
            state.selectionManager.toggleSelection(lastKey);
          }
        }
        break;
    }
  };

  const onBlur = (e: FocusEvent<Element>) => {
    // Ignore blur if focus moved to a related element
    if (
      e.relatedTarget === buttonRef?.current ||
      popoverRef.current?.contains(e.relatedTarget) ||
      tagGroupRef.current?.contains(e.relatedTarget)
    ) {
      inputRef.current?.focus();
      return;
    }

    if (props.onBlur) {
      props.onBlur(e as FocusEvent<HTMLInputElement>);
    }

    state.setFocused(false);
  };

  const onFocus = (e: FocusEvent<Element>) => {
    if (state.isFocused) {
      return;
    }

    if (props.onFocus) {
      props.onFocus(e as FocusEvent<HTMLInputElement>);
    }

    state.setFocused(true);
  };

  const { isInvalid, validationErrors, validationDetails } = state.displayValidation;
  const { labelProps, inputProps, descriptionProps, errorMessageProps } = useTextField(
    {
      ...props,
      onChange: state.setInputValue,
      onKeyDown: !isReadOnly
        ? chain(state.isOpen && collectionProps.onKeyDown, onKeyDown, props.onKeyDown)
        : undefined,
      value: state.inputValue,
      onBlur,
      onFocus,
      autoComplete: "off",
      validate: undefined,
      [privateValidationStateProp]: state,
    },
    inputRef
  );

  // Press handlers for the ComboBox button
  const onPress = (e: PressEvent) => {
    if (e.pointerType === "touch") {
      // Focus the input field in case it isn't focused yet
      inputRef.current?.focus();
      state.toggle(null, "manual");
    }
  };

  const onPressStart = (e: PressEvent) => {
    if (e.pointerType !== "touch") {
      inputRef.current?.focus();
      state.toggle(
        e.pointerType === "keyboard" || e.pointerType === "virtual" ? "first" : null,
        "manual"
      );
    }
  };

  const triggerLabelProps = useLabels({
    id: menuTriggerProps.id,
    "aria-label": "Show suggestions",
    "aria-labelledby": props["aria-labelledby"] || labelProps.id,
  });

  const listBoxProps = useLabels({
    id: menuProps.id,
    "aria-label": "Suggestions",
    "aria-labelledby": props["aria-labelledby"] || labelProps.id,
  });

  // If a touch happens on direct center of ComboBox input, might be virtual click from iPad so open ComboBox menu
  const lastEventTime = useRef(0);
  const onTouchEnd = (e: TouchEvent) => {
    if (isDisabled || isReadOnly) {
      return;
    }

    // Sometimes VoiceOver on iOS fires two touchend events in quick succession. Ignore the second one.
    if (e.timeStamp - lastEventTime.current < 500) {
      e.preventDefault();
      inputRef.current?.focus();
      return;
    }

    const rect = (e.target as Element).getBoundingClientRect();
    const touch = e.changedTouches[0];

    const centerX = Math.ceil(rect.left + 0.5 * rect.width);
    const centerY = Math.ceil(rect.top + 0.5 * rect.height);

    if (touch.clientX === centerX && touch.clientY === centerY) {
      e.preventDefault();
      inputRef.current?.focus();
      state.toggle(null, "manual");

      lastEventTime.current = e.timeStamp;
    }
  };

  // VoiceOver has issues with announcing aria-activedescendant properly on change
  // (especially on iOS). We use a live region announcer to announce focus changes
  // manually. In addition, section titles are announced when navigating into a new section.
  const focusedItem =
    state.selectionManager.focusedKey != null && state.isOpen
      ? state.collection.getItem(state.selectionManager.focusedKey)
      : undefined;
  const sectionKey = focusedItem?.parentKey ?? null;
  const itemKey = state.selectionManager.focusedKey ?? null;
  const lastSection = useRef(sectionKey);
  const lastItem = useRef(itemKey);
  useEffect(() => {
    if (isAppleDevice() && focusedItem != null && itemKey && itemKey !== lastItem.current) {
      const isSelected = state.selectionManager.isSelected(itemKey);
      const section = sectionKey != null ? state.collection.getItem(sectionKey) : null;
      const sectionTitle =
        section?.["aria-label"] ||
        (typeof section?.rendered === "string" ? section.rendered : "") ||
        "";
      const announcement = getFocusAnnouncement({
        isGroupChange: !!section && sectionKey !== lastSection.current,
        groupTitle: sectionTitle,
        groupCount: section ? [...getChildNodes(section, state.collection)].length : 0,
        optionText: focusedItem["aria-label"] || focusedItem.textValue || "",
        isSelected,
      });
      announce(announcement);
    }

    lastSection.current = sectionKey;
    lastItem.current = itemKey;
  });

  // Announce the number of available suggestions when it changes
  const optionCount = getItemCount(state.collection);
  const lastSize = useRef(optionCount);
  const lastOpen = useRef(state.isOpen);
  useEffect(() => {
    // Only announce the number of options available when the menu opens if there is no
    // focused item, otherwise screen readers will typically read e.g. "1 of 6".
    // The exception is VoiceOver since this isn't included in the message above.
    const didOpenWithoutFocusedItem =
      state.isOpen !== lastOpen.current &&
      (state.selectionManager.focusedKey == null || isAppleDevice());

    if (state.isOpen && (didOpenWithoutFocusedItem || optionCount !== lastSize.current)) {
      const announcement =
        optionCount !== 1 ? `${optionCount} options available` : `1 option available`;
      announce(announcement);
    }

    lastSize.current = optionCount;
    lastOpen.current = state.isOpen;
  });

  useEffect(() => {
    if (state.isOpen) {
      return ariaHideOutside(
        [inputRef.current, popoverRef.current].filter(el => !!el) as Element[]
      );
    }
  }, [state.isOpen, inputRef, popoverRef]);

  return {
    labelProps,
    buttonProps: {
      ...menuTriggerProps,
      ...triggerLabelProps,
      excludeFromTabOrder: true,
      onPress,
      onPressStart,
      isDisabled: isDisabled || isReadOnly,
    },
    inputProps: mergeProps(inputProps, {
      role: "combobox",
      "aria-label": "Search",
      "aria-expanded": menuTriggerProps["aria-expanded"],
      "aria-controls": state.isOpen ? menuProps.id : undefined,
      // TODO: re-add proper logic for completionMode = complete (aria-autocomplete: both)
      "aria-autocomplete": "list",
      "aria-activedescendant": focusedItem ? getItemId(state, focusedItem.key) : undefined,
      onTouchEnd,
      // This disables iOS's autocorrect suggestions, since the combobox provides its own suggestions.
      autoCorrect: "off",
      // This disables the macOS Safari spell check auto corrections.
      spellCheck: "false",
    }),
    listBoxProps: mergeProps(menuProps, listBoxProps, {
      autoFocus: state.focusStrategy,
      shouldUseVirtualFocus: true,
      shouldSelectOnPressUp: true,
      shouldFocusOnHover: true,
      linkBehavior: "selection" as const,
    }),
    descriptionProps,
    errorMessageProps,
    isInvalid,
    validationErrors,
    validationDetails,
  };
}

interface FocusAnnouncementParameters {
  isGroupChange: boolean;
  groupTitle: string;
  groupCount: number;
  optionText: string;
  isSelected: boolean;
}

const getFocusAnnouncement = ({
  isGroupChange,
  groupTitle,
  groupCount,
  optionText,
  isSelected,
}: FocusAnnouncementParameters) => {
  let announcement = "";
  if (isGroupChange) {
    announcement += `Entered group ${groupTitle}, with ${groupCount} ${pluralize(
      groupCount,
      "option"
    )}. ${optionText}${isSelected ? ", selected" : ""}`;
  }

  return `${announcement}${optionText}${isSelected ? ", selected" : ""}`;
};
