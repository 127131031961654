import { motion } from "framer-motion";
import { cloneElement, forwardRef, isValidElement, useCallback } from "react";
import { chain, mergeProps, useFocusRing, useHover, useTab } from "react-aria";
import scrollIntoView from "scroll-into-view-if-needed";

import { useDomRef } from "../../hooks/useDomRef";
import { useIsMounted } from "../../hooks/useIsMounted";
import { cn } from "../../utils/cn";
import { filterDOMProps } from "../../utils/filterDomProps";
import { Badge } from "../badge/Badge";

import type { ReactElement, ReactNode, RefObject } from "react";
import type { Node, TabListState } from "react-stately";
import type { EmptyObject } from "@skydio/core";
import type { BadgeProps } from "../badge/Badge";
import type { TabProps } from "./Tab";
import type { TabsClassNames, TabsSlots } from "./Tabs";

export interface TabItemProps<T extends object = EmptyObject> extends TabProps<T> {
  item: Node<T>;
  state: TabListState<T>;
  slots: TabsSlots;
  classNames?: TabsClassNames;
  listRef?: RefObject<HTMLDivElement>;
  isDisabled?: boolean;
}

export const isTabTitleBadge = (node: ReactNode): node is ReactElement<BadgeProps, typeof Badge> =>
  isValidElement(node) && node.type === Badge;

export const TabItem = forwardRef<HTMLElement, TabItemProps>(
  (
    {
      item,
      state,
      slots,
      classNames,
      className,
      listRef,
      isDisabled: isDisabledProp,
      titleValue,
      onClick,
      ...props
    },
    ref
  ) => {
    const Component = props.href ? "a" : "button";

    const { key, rendered } = item;

    const domRef = useDomRef(ref);

    const {
      tabProps,
      isSelected,
      isDisabled: isDisabledItem,
      isPressed,
    } = useTab({ key, isDisabled: isDisabledProp }, state, domRef);

    if (props.children == null) {
      delete tabProps["aria-controls"];
    }

    const isDisabled = isDisabledItem || isDisabledProp;

    const { focusProps, isFocused, isFocusVisible } = useFocusRing();
    const { hoverProps, isHovered } = useHover({ isDisabled });

    const [isMounted] = useIsMounted({ rerender: true });

    const handleClick = useCallback(() => {
      chain(onClick, tabProps.onClick);

      if (!domRef.current || !listRef?.current) {
        return;
      }

      scrollIntoView(domRef.current, {
        scrollMode: "if-needed",
        behavior: "smooth",
        block: "end",
        inline: "end",
        boundary: listRef.current,
      });
    }, [onClick, tabProps.onClick, domRef, listRef]);

    const hasBadge = isTabTitleBadge(rendered);
    const children = hasBadge ? rendered.props.children : rendered;

    const node = (
      <Component
        ref={domRef as RefObject<HTMLButtonElement & HTMLAnchorElement>}
        data-disabled={isDisabledItem}
        data-focus={isFocused}
        data-focus-visible={isFocusVisible}
        data-hover={isHovered}
        data-hover-unselected={(isHovered || isPressed) && !isSelected}
        data-pressed={isPressed}
        data-selected={isSelected}
        data-slot="tab"
        {...mergeProps(
          tabProps,
          !isDisabled
            ? {
                ...focusProps,
                ...hoverProps,
              }
            : {},
          filterDOMProps(props, { omitPropNames: new Set(["title"]) }),
          { onClick: handleClick }
        )}
        className={slots.tab({ className: cn(classNames?.tab, className) })}
        title={titleValue}
        type={Component === "button" ? "button" : undefined}
      >
        {isSelected && isMounted ? (
          <motion.span
            className={slots.cursor({ className: classNames?.cursor })}
            data-slot="cursor"
            layoutDependency={false}
            layoutId="cursor"
            transition={{
              type: "spring",
              bounce: 0.15,
              duration: 0.5,
            }}
          />
        ) : null}
        <div
          data-slot="tabContent"
          className={slots.tabContent({ className: classNames?.tabContent })}
        >
          {children}
        </div>
      </Component>
    );

    // If the user has provided a badge in the title, we want to wrap the badge around the whole tab
    // instead of just the inner content
    if (hasBadge) {
      return cloneElement(rendered, { children: node, classNames: { badge: slots.badge() } });
    }

    return node;
  }
);
TabItem.displayName = "Tabs.TabItem";
