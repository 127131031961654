export const scaleSpringOpacity = {
  initial: {
    opacity: 0,
    transform: "scale(0.8)",
  },
  enter: {
    opacity: 1,
    transform: "scale(1)",
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.3,
    },
  },
  exit: {
    opacity: 0,
    transform: "scale(0.7)",
    transition: {
      type: "easeOut",
      bounce: 0,
      duration: 0.18,
    },
  },
};

export const scaleSpring = {
  enter: {
    transform: "scale(1)",
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.3,
    },
  },
  exit: {
    transform: "scale(0.6)",
    opacity: 0,
    transition: {
      type: "easeOut",
      duration: 0.2,
    },
  },
};

export const ease = [0.36, 0.66, 0.4, 1];

export const fade = {
  enter: {
    opacity: 1,
    transition: {
      duration: 0.4,
      ease,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
      ease,
    },
  },
};

export const modalScaleInOut = {
  enter: {
    scale: 1,
    opacity: 1,
    transition: {
      scale: {
        duration: 0.4,
        ease,
      },
      opacity: {
        duration: 0.4,
        ease,
      },
      y: {
        type: "spring",
        bounce: 0,
        duration: 0.6,
      },
    },
  },
  exit: {
    scale: 1.03,
    opacity: 0,
    transition: {
      duration: 0.3,
      ease,
    },
  },
};
