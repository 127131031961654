import { useListBoxSection } from "@react-aria/listbox";
import { mergeProps } from "@react-aria/utils";
import { Section } from "@react-stately/collections";
import { useMemo } from "react";
import { tv } from "tailwind-variants";

import { ListBoxItemInternal } from "./ListBoxItem";

import type { ListState } from "@react-stately/list";
import type { Node, SectionProps } from "@react-types/shared";
import type { ComponentPropsWithoutRef } from "react";
import type { SlotsToClasses } from "../../utils/types";

const listBoxSectionVariants = tv({
  slots: {
    base: "relative mb-2 list-none last-of-type:mb-0",
    heading: "pl-1 text-xs text-gray-500",
    group: "p-0 data-[has-title=true]:pt-1",
    divider: "mt-2",
  },
});

export type ListBoxSectionProps<T extends object> = Omit<
  ComponentPropsWithoutRef<"li">,
  "children" | "title"
> &
  SectionProps<T> & {
    classNames?: SlotsToClasses<keyof ReturnType<typeof listBoxSectionVariants>>;
  };

export const ListBoxSection = Section as <T extends object>(
  props: ListBoxSectionProps<T>
) => JSX.Element;

export interface ListBoxSectionInternalProps<T extends object> extends ListBoxSectionProps<T> {
  item: Node<T>;
  state: ListState<T>;
}

/**
 * @internal
 */
export const ListBoxSectionInternal = <T extends object>({
  item,
  state,
  color,
  className,
  classNames,
  // removed title from props to avoid browsers showing a tooltip on hover
  // the title props is already inside the rendered prop
  title,
  ...otherProps
}: ListBoxSectionInternalProps<T>) => {
  const slots = useMemo(() => listBoxSectionVariants(), []);

  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: item.rendered,
    "aria-label": item["aria-label"],
  });

  return (
    <li
      key={item.key}
      data-slot="base"
      {...mergeProps(itemProps, otherProps)}
      className={slots.base({ className })}
    >
      {item.rendered && (
        <span
          {...headingProps}
          className={slots.heading({ className: classNames?.heading })}
          data-slot="heading"
        >
          {item.rendered}
        </span>
      )}
      <ul
        {...groupProps}
        className={slots.group({ className: classNames?.group })}
        data-has-title={!!item.rendered}
        data-slot="group"
      >
        {[...item.childNodes].map(node => {
          const { key: nodeKey, props: nodeProps } = node;

          let listboxItem = (
            <ListBoxItemInternal key={nodeKey} item={node} state={state} {...nodeProps} />
          );

          if (node.wrapper) {
            listboxItem = node.wrapper(listboxItem);
          }

          return listboxItem;
        })}
      </ul>
    </li>
  );
};
