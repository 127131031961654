import { faCheck, faEdit, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef } from "react";

import { Button } from "../button/Button";
import { Input } from "../input/Input";
import { Heading } from "../typography/Heading";
import { useEditableText } from "./useEditableText";

import type { HeadingProps } from "../typography/Heading";
import type { UseEditableTextProps } from "./useEditableText";

export type EditableHeadingProps = UseEditableTextProps & {
  level?: HeadingProps["level"];
};

const getTextSizeFromHeadingLevel = (
  level: HeadingProps["level"]
): UseEditableTextProps["size"] => {
  switch (level) {
    case 1:
      return "4xl";
    case 2:
      return "3xl";
    case 3:
      return "2xl";
    case 4:
      return "xl";
    case 5:
      return "lg";
    case 6:
      return "md";
  }
};

export const EditableHeading = forwardRef<HTMLDivElement, EditableHeadingProps>(
  ({ level, ...props }, ref) => {
    const {
      isEditing,
      textValue,
      baseProps,
      inputProps,
      editButtonProps,
      confirmButtonProps,
      cancelButtonProps,
      textProps,
    } = useEditableText({
      ...props,
      size: getTextSizeFromHeadingLevel(level),
      bold: true,
    });

    return (
      <div ref={ref} {...baseProps}>
        {isEditing ? (
          <>
            <Input {...inputProps} />
            <Button key="confirm" {...confirmButtonProps}>
              <FontAwesomeIcon icon={faCheck} />
            </Button>
            <Button key="cancel" {...cancelButtonProps}>
              <FontAwesomeIcon icon={faXmark} />
            </Button>
          </>
        ) : (
          <>
            <Heading {...textProps} level={level}>
              {textValue}
            </Heading>
            <Button key="edit" {...editButtonProps}>
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </>
        )}
      </div>
    );
  }
);
EditableHeading.displayName = "Heading.Editable";
