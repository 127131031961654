import { Item } from "react-stately";

import type { ComponentPropsWithoutRef, ReactElement, ReactNode } from "react";
import type { ItemProps } from "react-stately";
import type { EmptyObject } from "@skydio/core";

export interface TabProps<T extends object = EmptyObject>
  extends Omit<ItemProps<T>, "children">,
    Omit<ComponentPropsWithoutRef<"button">, "children" | "title"> {
  children?: ReactNode | null;
  title?: ReactNode | null;
  titleValue?: string;
  isDisabled?: boolean;
}

export const Tab = Item as (<T extends object>(props: TabProps<T>) => ReactElement) & {
  displayName: "Tabs.Tab";
};
Tab.displayName = "Tabs.Tab";
