import { useId, useMemo, useRef, useState } from "react";
import { mergeProps, useButton, useFocusRing, useModalOverlay } from "react-aria";
import { useOverlayTriggerState } from "react-stately";
import { tv } from "tailwind-variants";

import { useDomRef } from "../../hooks/useDomRef";
import { cn } from "../../utils/cn";
import { dataFocusVisible } from "../../utils/tailwindClasses";

import type { HTMLAttributes, RefAttributes } from "react";
import type { AriaModalOverlayProps } from "react-aria";
import type { OverlayTriggerProps } from "react-stately";
import type { VariantProps } from "tailwind-variants";
import type { DOMAttributes, ReactRef, SlotsToClasses } from "../../utils/types";

const modalVariants = tv({
  slots: {
    wrapper:
      "fixed inset-0 z-50 flex h-dvh w-screen justify-center overflow-x-auto bg-gray-900/30 backdrop-blur-sm",
    base: [
      "relative",
      "m-1",
      "box-border",
      "flex",
      "w-full",
      "flex-col",
      "rounded-lg",
      "border",
      "bg-white",
      "dark:bg-gray-850",
      "dark:text-white",
      "shadow-cardHover",
      "outline-none",
      "sm:mx-6",
      "sm:my-16",
    ],
    backdrop: "z-50",
    closeButton: [
      "absolute",
      "appearance-none",
      "border-none",
      "bg-transparent",
      "outline-none",
      "select-none",
      "cursor-pointer",
      "top-3.5",
      "right-3.5",
      "w-8",
      "h-8",
      "text-base",
      "text-gray-500",
      "rounded-full",
      "hover:bg-gray-50",
      "dark:hover:bg-gray-800",
      "active:bg-gray-200",
      "dark:active:bg-gray-700",
      "transition-colors",
      "tap-highlight-transparent",
      ...dataFocusVisible,
    ],
    body: "flex flex-1 flex-col gap-3 px-6 py-2",
    header: "flex flex-initial flex-col gap-1.5 px-6 py-4",
    title: "",
    description: "",
    footer: "flex flex-row justify-end gap-2 px-6 py-4",
  },
  variants: {
    size: {
      xs: {
        base: "max-w-xs",
      },
      sm: {
        base: "max-w-sm",
      },
      md: {
        base: "max-w-md",
      },
      lg: {
        base: "max-w-lg",
      },
      xl: {
        base: "max-w-xl",
      },
      "2xl": {
        base: "max-w-2xl",
      },
      "3xl": {
        base: "max-w-3xl",
      },
      "4xl": {
        base: "max-w-4xl",
      },
      full: {
        base: "m-0 h-dvh max-w-full !rounded-none sm:m-0",
      },
    },
    placement: {
      auto: {
        wrapper: "items-end sm:items-center",
      },
      center: {
        wrapper: "items-center sm:items-center",
      },
      top: {
        wrapper: "items-start sm:items-start",
      },
      bottom: {
        wrapper: "items-end sm:items-end",
      },
    },
    scrollBehavior: {
      normal: {
        base: "overflow-y-hidden",
      },
      inside: {
        base: "max-h-[calc(100%_-_8rem)]",
        body: "overflow-y-auto",
      },
      outside: {
        wrapper: "items-start overflow-y-auto sm:items-start",
        base: "my-16",
      },
    },
  },
  defaultVariants: {
    size: "md",
    placement: "auto",
    scrollBehavior: "normal",
  },
});

export interface UseModalProps
  extends HTMLAttributes<HTMLElement>,
    OverlayTriggerProps,
    AriaModalOverlayProps,
    VariantProps<typeof modalVariants> {
  hideCloseButton?: boolean;
  portalContainer?: Element;
  onClose?: () => void;
  classNames?: SlotsToClasses<keyof ReturnType<typeof modalVariants>>;
}

export const useModal = (props: UseModalProps, ref: ReactRef<HTMLElement>) => {
  const {
    className,
    classNames,
    isOpen,
    defaultOpen,
    onOpenChange,
    isDismissable = true,
    portalContainer,
    isKeyboardDismissDisabled,
    onClose,
    hideCloseButton,
    size,
    placement,
    scrollBehavior,
    ...otherProps
  } = props;
  const domRef = useDomRef(ref);
  const closeButtonRef = useRef<HTMLElement>(null);

  const [headerMounted, setHeaderMounted] = useState(false);
  const [bodyMounted, setBodyMounted] = useState(false);

  const dialogId = useId();
  const headerId = useId();
  const bodyId = useId();

  const state = useOverlayTriggerState({
    isOpen,
    defaultOpen,
    onOpenChange: isOpen => {
      onOpenChange?.(isOpen);
      if (!isOpen) {
        onClose?.();
      }
    },
  });

  const { modalProps, underlayProps } = useModalOverlay(
    {
      isDismissable,
      isKeyboardDismissDisabled,
    },
    state,
    domRef
  );

  const { buttonProps: ariaCloseButtonProps } = useButton({ onPress: state.close }, closeButtonRef);
  const { isFocusVisible: isCloseButtonFocusVisible, focusProps: closeButtonFocusProps } =
    useFocusRing();

  const slots = useMemo(
    () => modalVariants({ size, placement, scrollBehavior }),
    [size, placement, scrollBehavior]
  );

  const getDialogProps = (props: DOMAttributes = {}) =>
    ({
      ref: domRef,
      ...mergeProps(modalProps, otherProps, props),
      className: slots.base({ className: cn(classNames?.base, className, props.className) }),
      id: dialogId,
      "data-open": state.isOpen,
      "data-dismissable": isDismissable,
      "aria-modal": true,
      "aria-labelledby": headerMounted ? headerId : undefined,
      "aria-describedby": bodyMounted ? bodyId : undefined,
    }) as DOMAttributes & RefAttributes<any>;

  const backdropProps = useMemo(
    () => ({
      className: slots.backdrop({ className: classNames?.backdrop }),
      onClick: () => state.close,
      ...underlayProps,
    }),
    [slots, classNames, underlayProps, state.close]
  );

  const closeButtonProps = useMemo(
    () => ({
      role: "button",
      tabIndex: 0,
      "aria-label": "Close",
      "data-focus-visible": isCloseButtonFocusVisible,
      className: slots.closeButton({ className: classNames?.closeButton }),
      ...mergeProps(ariaCloseButtonProps, closeButtonFocusProps),
    }),
    [slots, classNames, ariaCloseButtonProps, closeButtonFocusProps, isCloseButtonFocusVisible]
  );

  return {
    slots,
    domRef,
    headerId,
    bodyId,
    classNames,
    isDismissable,
    portalContainer,
    isOpen: state.isOpen,
    onClose: state.close,
    setBodyMounted,
    setHeaderMounted,
    getDialogProps,
    backdropProps,
    closeButtonProps,
    hideCloseButton,
  };
};

export type UseModalReturn = ReturnType<typeof useModal>;
